#contact-form {
	max-width: 700px;

	.input,
	.textarea,
	textarea,
	input[type='text'],
	input[type='password'],
	input[type='email'] {
		background-color: $soft-bg;
		border-color: $input-bdr;
		color: $main-text-color;

		&:focus,
		&:active {
			box-shadow: none;
		}

		@include placeholderText {
			font-weight: normal;
			font-size: 0.9rem;
			font-style: italic;
			color: $placeholder-color;
		}
	}

	.label {
		color: $form-labels;

		.required:after {
			content: '[ required ]';
			font-size: 0.7em;
			float: right;
			opacity: $required-opacity;
			margin-top: 5px;
		}
	}

	.control.has-icons-left .input:focus ~ .icon,
	.control.has-icons-left .select:focus ~ .icon,
	.control.has-icons-right .input:focus ~ .icon,
	.control.has-icons-right .select:focus ~ .icon {
		color: $input-icon-color;
	}

	.button.is-text {
		&:hover,
		&:active {
			background-color: $reset-btn-over-bg;
			color: $reset-btn-over-color;
		}
	}

	.button.is-link {
		background-color: $submit-btn-bg;
		color: $submit-btn-color;

		&:hover,
		&:active {
			background-color: $submit-btn-over-bg;
			color: $submit-btn-over-color;
		}
	}

	.grecaptcha-badge {
		display: none;
	}

	.help.is-danger {
		color: $error-color;
		background-color: $error-bg;
		font-weight: bold;
		padding: 5px 12px;

		&:before {
			font-family: 'Font Awesome 5 Free';
			content: '\f06a';
			margin-right: 10px;
		}
	}

	.input.is-danger,
	.textarea.is-danger {
		border-color: $error-bg;

		+ .icon {
			color: $error-bg;
		}
	}

	.submitted {
		display: none;
	}

	.thankyou {
		display: block;
		color: $contentHeading;
		font-size: 25px;
		font-style: normal;
		font-weight: 700;
		line-height: 30px;
	}

	.contact-buzzoff {
		margin-bottom: 15px;
		font-style: italic;
		color: $buzz-off-color;
		padding-bottom: 10px;
		border-bottom: 1px solid $buzz-off-border;

		strong {
			color: $main-text-color;
		}
	}
}
