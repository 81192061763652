#socialLinks {
	opacity: 0.2;
	@include transition(opacity 400ms);

	&:hover,
	&:active {
		opacity: 1;
	}
}

.social-networks {
	margin: 50px 0 35px;
	padding: 0;
	font-size: 0;
	text-align: center;

	li {
		display: inline-block;

		&:first-child a:before {
			border-radius: 5px 0 0 5px;
		}

		&:last-child a:before {
			border-radius: 0 5px 5px 0;
		}

		a {
			display: block;
			position: relative;
			margin: 0;
			width: 68px;
			height: 68px;
			font-size: 0;
			line-height: 0;
			text-align: center;
			text-decoration: none;

			&:before {
				// Icon Background
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				height: 100%;
				margin-top: -50%;
				margin-left: -50%;
				border-radius: 0;
				background-color: $social-icon-bg;
				@include transition(all 400ms);
			}

			&:after {
				// Icon Symbol
				position: relative;
				display: block;
				font-family: 'Font Awesome 5 Free';
				text-transform: none;
				font-size: 23px;
				line-height: 66px;
				text-shadow: 1px 1px 1px darken($social-icon-bg, 10);
				@include transition(all 0.4s ease-in);
			}

			&:hover,
			&:focus {
				text-decoration: none;

				&:after {
					@include transform(rotateZ(360deg));
				}

				&:before {
					border-radius: 100px;
					border: 2px solid $social-icon-color;
				}
			}
		}

		// Icon specific styling
		@each $label, $map in $icons {
			$iconColor: map-get($map, color);
			$iconShadow: darken($iconColor, 10);

			a.icon-#{$label} {
				&:after {
					font-family: map-get($map, font); //'Font Awesome 5 Brands';//'Font Awesome 5 Free';
					content: map-get($map, icon);
					color: $social-icon-color;
				}

				:before {
					border: 0 solid $iconColor;
				}

				&:hover,
				&:focus {
					&:before {
						background: $iconColor;
						border: 2px solid $iconShadow;
					}

					&:after {
						text-shadow: 2px 2px 3px $iconShadow;
						color: $social-icon-active-color;
					}
				}
			}
		}
	}
}
