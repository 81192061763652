// Tooltips
*[data-dgtip] {
	overflow: visible;
	position: relative;
	cursor: pointer;
	&:after {
		@include transition(all 0.18s ease-out 0.18s);
		@include transform(translate(-50%, 10px));
		@include transform-origin(top);
		background: $tip-bg;
		border-radius: 4px;
		bottom: 100%;
		color: $tip-text;
		content: attr(data-dgtip);
		font-size: 12px;
		left: 50%;
		margin-bottom: 11px;
		opacity: 0;
		padding: 0.5em 1em;
		pointer-events: none;
		position: absolute;
		white-space: nowrap;
		width: auto;
		z-index: 10;
	}
	&:before {
		@include transform(translate(-50%, 10px));
		@include transform-origin(top);
		@include transition(all 0.18s ease-out 0.18s);
		@include tipTriangle($tip-bg);
		background-size: 100% auto;
		bottom: 100%;
		content: '';
		height: 12px;
		left: 50%;
		margin-bottom: -1px;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		width: 18px;
		z-index: 10;
	}
	&:hover:before,
	&:hover:after {
		@include transform(translate(-50%, 0));
		opacity: 1;
		pointer-events: auto;
	}
}
