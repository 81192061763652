////////// MIXINS \\\\\\\\\\
@mixin wrapquotes($color) {
	&:before,
	&:after {
		font-family: 'Font Awesome 5 Free';
		color: $color;
		font-weight: 700;
	}
	&:after {
		content: '\f10e';
		margin-left: 10px;
	}
	&:before {
		content: '\f10d';
		margin-right: 10px;
	}
}
@mixin trueheight($height) {
	height: $height;
	line-height: $height;
}
@mixin btn-shadow($color) {
	background-image: linear-gradient(180deg, lighten($color, 6%) 0%, $color 60%, darken($color, 4%) 100%);
	filter: none;
}
@mixin btn-shadow-inverse($color) {
	background-image: linear-gradient(180deg, darken($color, 8%) 0%, darken($color, 4%) 40%, darken($color, 0%) 100%);
	filter: none;
}
@mixin placeholderText {
	&::-webkit-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}
@mixin tipTriangle($fillColor: $black) {
	background: no-repeat
		url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22' + $fillColor + '%22%20transform%3D%22rotate(0)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
}
@mixin transform($transform...) {
	-moz-transform: $transform;
	-o-transform: $transform;
	-webkit-transform: $transform;
	transform: $transform;
}
@mixin transform-origin($origin...) {
	-moz-transform-origin: $origin;
	-o-transform-origin: $origin;
	-webkit-transform-origin: $origin;
	transform-origin: $origin;
}
@mixin transition($transition...) {
	-moz-transition: $transition;
	-o-transition: $transition;
	-webkit-transition: $transition;
	transition: $transition;
}
@mixin transition-property($property...) {
	-moz-transition-property: $property;
	-o-transition-property: $property;
	-webkit-transition-property: $property;
	transition-property: $property;
}
@mixin transition-duration($duration...) {
	-moz-transition-property: $duration;
	-o-transition-property: $duration;
	-webkit-transition-property: $duration;
	transition-property: $duration;
}
@mixin transition-timing-function($timing...) {
	-moz-transition-timing-function: $timing;
	-o-transition-timing-function: $timing;
	-webkit-transition-timing-function: $timing;
	transition-timing-function: $timing;
}
@mixin transition-delay($delay...) {
	-moz-transition-delay: $delay;
	-o-transition-delay: $delay;
	-webkit-transition-delay: $delay;
	transition-delay: $delay;
}
@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}

@mixin animation($animate...) {
	$max: length($animate);
	$animations: '';
	@for $i from 1 through $max {
		$animations: #{$animations + nth($animate, $i)};
		@if $i < $max {
			$animations: #{$animations + ', '};
		}
	}
	-webkit-animation: $animations;
	-moz-animation: $animations;
	-o-animation: $animations;
	animation: $animations;
}
@mixin animateDelay($delay: 0ms) {
	-webkit-animation-delay: $delay;
	-moz-animation-delay: $delay;
	-o-animation-delay: $delay;
	animation-delay: $delay;
}

@mixin rotate($deg: 90) {
	$sDeg: #{$deg}deg;
	@include transform(rotate($sDeg));
}
@mixin filter($filter...) {
	-webkit-filter: $filter;
	filter: $filter;
}
// Requires inline-block or block for proper styling
@mixin text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
@mixin linear-gradient($from, $to) {
	background-color: $from;
	background-image: -webkit-linear-gradient(top, $from, $to); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
	background-image: linear-gradient(to bottom, $from, $to); /* Chrome 26, Firefox 16+, IE 10+, Opera 12.10+ */
}
@mixin box-sizing($arguments) {
	-webkit-box-sizing: $arguments;
	-moz-box-sizing: $arguments;
	-o-box-sizing: $arguments;
	box-sizing: $arguments;
}
@mixin breakpoint($point) {
	@if $point == desktop {
		@media (min-width: 70rem) {
			@content;
		}
	} @else if $point == laptop {
		@media (min-width: 64rem) {
			@content;
		}
	} @else if $point == tablet {
		@media (min-width: 50rem) {
			@content;
		}
	} @else if $point == phablet {
		@media (min-width: 37.5rem) {
			@content;
		}
	} @else if $point == mobileonly {
		@media (max-width: 37.5rem) {
			@content;
		}
	}
}

@function setShadow($shadowValue, $useShadow: true) {
	@if $useShadow == true {
		@return $shadowValue;
	}
	@return none;
}
