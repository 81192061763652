#about {
	#aboutcontent {
		> h2 {
			color: $contentHeading;
			font-size: 25px;
			font-style: normal;

			line-height: 30px;
			padding-bottom: 20px;
			padding-top: 20px;
			text-transform: none;
			@include wrapquotes($wrapQuotes);
		}

		> div {
			margin: 20px 30px;
		}
	}
}
